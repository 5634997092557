import React from "react";
import { styled } from "@hiyllo/ux/styled";
import { CircleButton } from "@hiyllo/ux/circle-button";
import { useCustomDataForProjects } from "@hiyllo/omni-tasks";
import { faEllipsisV, faSquare, faSquareCheck } from "@fortawesome/pro-light-svg-icons";
import { ContextMenuContainer, ContextMenuItem, useContextMenu } from "@hiyllo/ux/context-menu";

import { useWorkerResult } from "../../../platform/worker";
import { TaskKanbanView } from "../kanban-view/task-kanban-view";
import { type ListTasksSlimTaskType } from "../../../types/tasks/task-item";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { TaskPriorityV3, TaskStatusV3, type TaskOrderType } from "../../../types/tasks/tasks-organization";

export type ProjectData = {
  statuses: TaskStatusV3[] | null;
  priorities: TaskPriorityV3[] | null;
};

const Container = styled("div", {
  height: "100%",
  padding: 0,
  display: "flex",
  flexDirection: "column",
  gap: 10,
});

const ViewContainer = styled('div', {
  height: 0,
  flexGrow: 1,
});

const SpaceBetweenRow = styled("div", ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}));

export interface TasksViewPropsType {
  extraHeaderOptions?: (JSX.Element | null)[] | JSX.Element;
  order: TaskOrderType;
  onChangeOrder: (
    order: TaskOrderType,
    updatedTask: ListTasksSlimTaskType | null,
  ) => void;
  tasks: ListTasksSlimTaskType[];
  isReady?: boolean;
  columns?: string[] | null;
  hideHeader?: boolean;
  onClickTask?: (task: ListTasksSlimTaskType) => boolean;
  hideTaskProjectLabels?: boolean;
}

export const TasksView = React.memo(function TasksView(
  props: TasksViewPropsType,
): JSX.Element {
  const [showWontDo, setShowWontDo] = React.useState<boolean>(false);
  const cxMenu = useContextMenu();

  const ViewComponent = TaskKanbanView;

  const projects = useWorkerResult<ListTasksSlimTaskType[], string[]>(new URL("./workers/reduce-to-project-uuids", import.meta.url), props.tasks);
  const rawData = useCustomDataForProjects(projects ?? []);
  const rawColumns = useWorkerResult<ProjectData[] | null, TaskStatusV3[]>(new URL("./workers/compute-columns", import.meta.url), rawData);
  const columns = React.useMemo(() => {
    return showWontDo ? rawColumns : rawColumns?.filter((column) => column.hideFromKanban !== true);
  }, [rawColumns, showWontDo]);

  if (columns == null) {
    return <LoadingSpinnerFullView />;
  }

  return (
    <Container>
      <cxMenu.CXMenuContainer>
        <ContextMenuContainer>
          <ContextMenuItem
            icon={showWontDo ? faSquareCheck : faSquare}
            label="Show Hidden Columns"
            onClick={() => {
              setShowWontDo(!showWontDo);
            }}
          />
        </ContextMenuContainer>
      </cxMenu.CXMenuContainer>
      <SpaceBetweenRow>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>
          {props.extraHeaderOptions ?? <div />}
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>
          {/* <PillSelect
            options={viewTypeOptions}
            value={viewType}
            onChangeValue={(value) => {
              window.localStorage.preferredTaskView = value;
              setViewType(value as "kanban" | "list");
            }}
            loading={false}
          /> */}
          <CircleButton
            icon={faEllipsisV}
            onClick={(evt) => {
              if (evt != null) cxMenu.open(evt);
            }}
            size={27.5}
            secondary
          />
        </div>
      </SpaceBetweenRow>
      <ViewContainer>
        <ViewComponent
          order={props.order}
          onChangeOrder={props.onChangeOrder}
          tasks={props.tasks}
          isReady={props.isReady}
          columns={columns}
          hideTaskProjectLabels={props.hideTaskProjectLabels}
        />
      </ViewContainer>
    </Container>
  );
});

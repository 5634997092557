import React from "react";
import { useSelf } from "@hiyllo/omni-continuity";
import { useGreeting } from "../hooks/use-greeting";
import { NymblIcon } from "@hiyllo/icons/main";
import { Text, View } from "react-native";
import { styledRN } from "@hiyllo/ux/styled";

const TextBlock = styledRN<"Text", { color: string }>(Text, ({ color }) => ({
  fontFamily: "hiyllo",
  fontSize: 18,
  color,
}));

const Container = styledRN(View, {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 2.5,
  paddingLeft: 5,
});

export const GreetingBlock = React.memo(function GreetingBlock(props: {
  color: string;
}): JSX.Element {
  const self = useSelf();

  const name = React.useMemo(() => {
    return self.profile?.name != null
      ? ", " + self.profile.name.split(" ")[0]
      : "";
  }, [self?.profile?.name]);

  const greeting = useGreeting(name);

  return (
    <Container>
      <span style={{ fontSize: 16, height: 16 }}>
        <NymblIcon width={16} color={props.color} />
      </span>
      <TextBlock color={props.color}>&nbsp;{greeting}</TextBlock>
    </Container>
  );
});

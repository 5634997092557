/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-async-promise-executor */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/*
    IndexedDB... Simplified.
    
    This simple class makes it easy for you to maintain a single collection of objects in IndexedDB. The purpose of this is to allow you to easily pass data from Web Workers to your application, allowing web workers to collect pushed data.
    
    Its very simple.
    
    1. Initialize your database
    let myDb = new SimpleIDB("myDbName");
    
    2. Open the DB
    await myDb.open();
    
    3. Write data
    await myDb.write(myDataObject);
    
    4. Get Data
    await myDb.getAll();
    
    5. Delete from the database
    await myDb.delete("id");
 */

import React from "react";

class SimpleIDB {
  name: string;
  db?: IDBDatabase;

  constructor(name: string) {
    this.name = name;
  }

  open = (): Promise<void> =>
    new Promise((resolve, reject) => {
      if (this.db) {
        return resolve();
      }

      const { name } = this;

      const openReq = indexedDB.open(name, 1);
      openReq.onerror = (evt) => {
        reject(evt);
      };
      openReq.onsuccess = (evt: Event) => {
        if (evt.target != null) {
          // @ts-expect-error
          this.db = evt.target.result;
          resolve();
        }
      };
      openReq.onupgradeneeded = (evt) => {
        // @ts-expect-error
        this.db = evt.target.result;
        if (this.db == null) {
          return console.error("IDB56");
        }
        this.db.createObjectStore("myObjectStore", { keyPath: "id" });
      };
    });

  write = (item: { id: string; data: any }) =>
    new Promise(async (resolve, reject) => {
      try {
        if (!this.db) {
          await this.open();
        }

        const transaction = this.db!.transaction(["myObjectStore"], "readwrite");

        const objectStore = transaction.objectStore("myObjectStore");
        const request = objectStore.put(item);
        request.onsuccess = resolve;
      }
      catch (e) {
        console.warn(e);
      }
    });

  delete = (id: string): Promise<void> =>
    new Promise((resolve, reject) => {
      try {
        if (!this.db) {
          return reject(new Error("DB is not open yet"));
        }

        const request = this.db
          .transaction(["myObjectStore"], "readwrite")
          .objectStore("myObjectStore")
          .delete(id);
        request.onsuccess = () => { };
      }
      catch (e) {
        console.warn(e);
      }
    });

  read = (key: string) =>
    new Promise<{ id: string; data: any } | null>(async (resolve, reject) => {
      try {
        if (!this.db) {
          await this.open();
        }

        const objectStore = this.db!.transaction(
          "myObjectStore",
          "readonly",
        ).objectStore("myObjectStore");
        const t = objectStore.get(key);
        t.onsuccess = (evt) => {
          // @ts-expect-error
          resolve(evt.target.result);
        };
        t.onerror = () => reject();
      }
      catch (e) {
        console.warn(e);
      }
    });

  readEJSON = async <T>(id: string, defaultValue: T): Promise<T> => {
    await this.open();
    const value = await this.read(id);
    return value != null ? (value.data as T) : defaultValue;
  };

  mutate = async <T>(id: string, defaultValue: T, mutator: (value: T) => T) => {
    await this.open();
    const current: T = (await this.read(id))?.data ?? defaultValue;
    const data = mutator(current);
    await this.write({
      id,
      data,
    });
  };

  readAll = (): Promise<Array<{ id: string; data: any }>> =>
    new Promise((resolve, reject) => {
      try {
        if (!this.db) {
          return reject(new Error("DB is not open yet"));
        }

        const objectStore = this.db
          .transaction("myObjectStore")
          .objectStore("myObjectStore");
        const t = objectStore.getAll();
        t.onsuccess = (evt) => {
          // @ts-expect-error
          resolve(evt.target.result);
        };
        t.onerror = () => reject();
      }
      catch (e) {
        console.warn(e);
      }
    });
}

export const LoadingSymbol = Symbol("idbloading");

export class ReactEnhancedSimpleIDB extends SimpleIDB {
  useAll = () => {
    const [d, s] = React.useState<
      Array<{ id: string; data: any }> | typeof LoadingSymbol
    >(LoadingSymbol);

    React.useEffect(() => {
      void this.open().then(() =>
        this.readAll().then((d) => s(d as Array<{ id: string; data: any }>)),
      );
    }, []);

    return d;
  };
}

export default SimpleIDB;

import React from "react";
import { pdfjs, Document, Page } from "react-pdf";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { LoadingSpinnerFullView } from "./loading/spinner-loading-full";
import { ContextMenuContainer, ContextMenuItem, useContextMenu } from "@hiyllo/ux/context-menu";
import { faEllipsisV, faSquare, faSquareCheck } from "@fortawesome/pro-light-svg-icons";
import { CircleButton } from "@hiyllo/ux/circle-button";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

export const PDFViewer = React.memo(function PDFViewer(props: { src: string }): JSX.Element {
  const cxMenu = useContextMenu();
  const [numPages, setNumPages] = React.useState(0);
  const onLoadSuccess = React.useCallback(({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  }, []);
  const [fitWidth, setFitWidth] = React.useState(false);

  const src = React.useMemo(() => new URL(props.src), [props.src]);
  const srcUrl = React.useMemo(() => {
    return src.toString();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src.hostname]);

  return (
    <>
      <cxMenu.CXMenuContainer>
        <ContextMenuContainer>
          <ContextMenuItem
            icon={fitWidth ? faSquareCheck : faSquare}
            label="Fit width to window"
            onClick={() => {
              setFitWidth(v => !v);
            }}
          />
        </ContextMenuContainer>
      </cxMenu.CXMenuContainer>
      {numPages === 0 ? <LoadingSpinnerFullView /> : null}
      <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", position: "relative" }}>
        <div style={{ height: "100%", width: fitWidth ? "100%" : "min-content", overflowY: "auto", display: numPages === 0 ? "none" : "" }}>
          <Document file={srcUrl} onLoadSuccess={onLoadSuccess} loading={<LoadingSpinnerFullView />}>
            <div style={{ display: "flex", flexDirection: "column", height: "100%", overflowY: "auto", gap: 10 }}>
              {new Array(numPages).fill(null).map((_, i) => (
                <Page pageNumber={i + 1} height={window.innerHeight - 60} width={fitWidth ? window.innerWidth - 60 : undefined} key={i} />
              ))}
            </div>
          </Document>
        </div>
        <div style={{ position: "absolute", bottom: 15, right: 15, zIndex: 100 }}>
          <CircleButton
            onClick={(evt) => {
              if (evt != null) cxMenu.open(evt);
            }}
            icon={faEllipsisV}
            square
            secondary
            size={30}
          />
        </div>
      </div>
    </>
  );
});